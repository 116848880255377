import { style } from '@do/walrus';
import React from 'react';
import styled, { css } from 'styled-components';

export const PillElt = styled.span<Props>`
  background-color: ${style.colors.primary.base};
  color: white;
  border-radius: 2px;
  padding: 3px 9px 2px;
  margin: 7px 0 0 12px;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-weight: bold;
  height: 26px;
  text-transform: uppercase;

  ${(props) =>
    props.small &&
    css`
      font-size: 11px;
      padding: 3px 5px 3px;
      height: 20px;
      margin: -2px 0 0 0;
    `}
`;

interface Props {
  text?: string;
  small?: boolean;
  className?: string;
}

const Pill: React.FC<Props> = ({ text, small, className }) => {
  return (
    <PillElt className={className} small={small}>
      {text}
    </PillElt>
  );
};

export default Pill;
