import _get from 'lodash/get';
import { AppType } from '../interfaces/OpenChannel';

export const isAppType = (type: AppType, input: any): boolean => {
  if (typeof input === 'string') {
    return input === type;
  }
  if (typeof input === 'object') {
    return _get(input, 'type') === type;
  }
  return false;
};

export const isKubernetes = (input: any): boolean =>
  isAppType(AppType.KUBERNETES, input);
export const isDroplet = (input: any): boolean =>
  isAppType(AppType.DROPLET, input);
export const isSaasAffiliate = (input: any): boolean =>
  isAppType(AppType.SAAS_AFFILIATE, input);
export const isSls = (input: any): boolean => isAppType(AppType.SLS, input);
export const isAIModel = (input: any): boolean =>
  isAppType(AppType.AI_MODEL, input);
export const isSaas = (input: any): boolean => isAppType(AppType.SAAS, input);
