import { trackEvent, trackGtmEvent } from '@do/seashell';
import _get from 'lodash/get';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const { ALGOLIA_MARKETPLACE_INDEX } = publicRuntimeConfig;
/*
  Google Analytics is loaded via Google Tag Manager
  We use this to get the first tracker name (should only be one)
  and return it
*/
function getGATrackerName() {
  let trackers = [];
  let firstTracker = null;
  let trackerName = null;

  if ((window as any).ga && (window as any).ga?.getAll) {
    trackers = (window as any).ga.getAll();
    if (trackers.length) {
      firstTracker = trackers[0];
      trackerName =
        _get(firstTracker, "a.data.values[':name']") ||
        _get(firstTracker, "b.data.values[':name']");
    }
  }

  return trackerName;
}

function gaTrackPage(page) {
  const trackerName = getGATrackerName();

  if (trackerName) {
    (window as any).ga(`${trackerName}.send`, 'pageview', page);
  }
}

// Segment Tracking
function trackPage(pageName, props = {}) {
  if ((window as any).analytics) {
    (window as any).analytics.page(pageName, props);
  }
}

const trackCategoryClick = (val) => {
  trackEvent('Change App Category', { category: val });
};

const trackUseCaseClick = (val) => {
  trackEvent('Change Use Case', { title: val });
};

//Algolia tracking
const trackProductSearch = (eventName, eventType, val) => {
  trackEvent(eventName, {
    index: ALGOLIA_MARKETPLACE_INDEX,
    eventType: eventType,
    queryID: val.queryId,
    objectID: val.objectId,
    position: val.position
  });
};

//GTM tracking
const trackGTMEvent = (eventPrefix, eventState, props) => {
  trackGtmEvent({
    type: 'track',
    event: `${eventPrefix}_${eventState}`,
    action: props?.action,
    name: props?.name,
    page_name: props?.pageName
  });
};

export {
  gaTrackPage,
  trackPage,
  trackCategoryClick,
  trackUseCaseClick,
  trackProductSearch,
  trackGTMEvent
};
