import _get from 'lodash/get';
import getConfig from 'next/config';
import {
  getAppId,
  getSafeName,
  getImageId,
  getImageLabel,
  getCreateApp
} from '../selectors';
import { isDroplet, isKubernetes, isSaas, isSls } from './appTypeHelpers';

const { publicRuntimeConfig } = getConfig();
const { SITE_URL, CLOUD_URL } = publicRuntimeConfig;

export default function getCreateButtonUrl(app: any): string {
  const appId = getAppId(app) ?? _get(app, 'appId');
  const safeName = getSafeName(app) ?? _get(app, 'safeName[0]');
  const imageId = getImageId(app) ?? _get(app, 'customData.imageId');
  const imageLabel = getImageLabel(app) ?? _get(app, 'customData.imageLabel');
  const createAppFromCustomData =
    getCreateApp(app) ?? _get(app, 'customData.createApp');

  if (isKubernetes(app)) {
    const origin = encodeURIComponent(SITE_URL);
    const referrerParam = `referrer=${origin}`;
    const appParam = `app_id=${appId}`;
    return `${CLOUD_URL}/kubernetes/clusters?${appParam}&${referrerParam}&activation_redirect=${encodeURIComponent(
      `/kubernetes/clusters?${appParam}&${referrerParam}`
    )}`;
  } else if (isDroplet(app)) {
    return `${CLOUD_URL}/droplets/new?onboarding_origin=marketplace&appId=${imageId}&image=${imageLabel}&activation_redirect=${encodeURIComponent(
      `/droplets/new?appId=${imageId}&image=${imageLabel}`
    )}`;
  } else if (isSaas(app) || isSls(app)) {
    return `${CLOUD_URL}/add-ons/create/${safeName}?activation_redirect=${encodeURIComponent(
      `/add-ons/create/${safeName}`
    )}`;
  } else {
    return createAppFromCustomData;
  }
}
