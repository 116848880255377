import _get from 'lodash/get';
import {
  getApps_getApps_apps_custom_data_additional_links as AdditionalLinks,
  getApps_getApps_apps_custom_data as CustomAppData,
  getApps_getApps_apps_custom_data_software_included as SoftwareIncluded,
  getApps_getApps_apps_custom_data_support as Support
} from '../graphql/generated-types/getApps';

export const getAppId = (app): string | undefined => _get(app, 'app_id');
export const getName = (app): string | undefined => _get(app, 'name');
export const getIsLive = (app): boolean | undefined => _get(app, 'is_live');
export const getType = (app): string | undefined => _get(app, 'type');
export const getSafeName = (app): string | undefined =>
  _get(app, 'safe_name[0]');
export const getCategory = (app): string | undefined =>
  _get(app, 'custom_data.tags[0]');
export const getTags = (app): string[] | undefined =>
  _get(app, 'custom_data.tags');
export const getCustomData = (app): CustomAppData | undefined =>
  _get(app, 'custom_data');
export const getIcon = (app): string | undefined =>
  _get(app, 'custom_data.icon');
export const getSummary = (app): string | undefined =>
  _get(app, 'custom_data.summary');
export const getDescription = (app): string | undefined =>
  _get(app, 'custom_data.description');
export const getSoftwareIncluded = (app): [SoftwareIncluded] | undefined =>
  _get(app, 'custom_data.software_included');
export const getGettingStarted = (app): string | undefined =>
  _get(app, 'custom_data.getting_started');
export const getAdditionalLinks = (app): AdditionalLinks[] | undefined =>
  _get(app, 'custom_data.additional_links');
export const getSupport = (app): Support | undefined =>
  _get(app, 'custom_data.support');
export const getImageId = (app): number | undefined =>
  _get(app, 'custom_data.image_id');
export const getImageLabel = (app): string | undefined =>
  _get(app, 'custom_data.image_label');
export const getAddonSlug = (app): string | undefined =>
  _get(app, 'custom_data.addon_slug'); // kubernetes only
export const getQuickAccessEnabled = (app): boolean | undefined =>
  _get(app, 'custom_data.quick_access_enabled');
export const getAccessPath = (app): string | undefined =>
  _get(app, 'custom_data.access_path');
export const getPort = (app): number | undefined =>
  _get(app, 'custom_data.port');
export const getCreateApp = (app): string | undefined =>
  _get(app, 'custom_data.create_app');
export const getDeveloperName = (app): string | undefined =>
  _get(app, 'developer.name');
export const getDeveloperIcon = (app): string | undefined => {
  try {
    return JSON.parse(_get(app, 'developer.custom_data'))?.icon;
  } catch {
    return null;
  }
};
export const getVersion = (app): string | undefined =>
  _get(app, 'custom_data.version');
export const getOsVersion = (app): string | undefined =>
  _get(app, 'custom_data.os_version');
export const getCreated = (app): number | undefined => _get(app, 'created');
export const getLastUpdated = (app): number | undefined =>
  _get(app, 'last_updated');
export const getStatus = (app): string | undefined => _get(app, 'status.value');
